import React from "react"

import Layout, {KiteLink, SubHeading} from "../components/layout"
import {EnvelopeSVG, TwitterSVG} from "../components/svg";

const ContactPage = () => (
  <Layout title="Contact" id="contact-page">
    <div><p>If you are interested in collaborating on a new project, or if you have an existing product you wish to improve or support, <KiteLink className="no-wrap" href="mailto:kite@madebykite.com">we'd love to hear from you</KiteLink>.</p></div>

    <div id="contact-kite-address">
      <strong>Kite Development &amp; Consulting Limited</strong>
      <br/>70 Rosamund Road
      <br/>Oxford
      <br/>OX2 8NX
    </div>
    <div id="contact-kite-email">
      <EnvelopeSVG /><KiteLink href="mailto:kite@madebykite.com">kite@madebykite.com</KiteLink>
    </div>
    <div id="contact-kite-twitter">
      <TwitterSVG /><KiteLink  href="https://twitter.com/madebykite">@madebykite</KiteLink>
    </div>
    <div id="contact-company-info">
      <hr />
      <span>
        Kite Development & Consulting Limited is an English company incorporated and registered in England & Wales with company number 07238871 whose registered office is at 70 Rosamund Road, Oxford, OX2 8NX, United Kingdom. 
      </span>
    </div>
  </Layout>
)

export default ContactPage
